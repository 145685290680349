/* eslint import/prefer-default-export:off */

export const UNSPLASH_DEFAULT_IMAGE = {
  urls: {
    raw: "https://images.unsplash.com/photo-1543726969-a1da85a6d334?ixid=M3wxNTIxOTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MTI3ODY1MTF8&ixlib=rb-4.0.3",
    full: "https://images.unsplash.com/photo-1543726969-a1da85a6d334?crop=entropy&cs=srgb&fm=jpg&ixid=M3wxNTIxOTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MTI3ODY1MTF8&ixlib=rb-4.0.3&q=85",
    regular: "https://images.unsplash.com/photo-1543726969-a1da85a6d334?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxNTIxOTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MTI3ODY1MTF8&ixlib=rb-4.0.3&q=80&w=1080",
    small: "https://images.unsplash.com/photo-1543726969-a1da85a6d334?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxNTIxOTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MTI3ODY1MTF8&ixlib=rb-4.0.3&q=80&w=400",
    thumb: "https://images.unsplash.com/photo-1543726969-a1da85a6d334?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxNTIxOTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MTI3ODY1MTF8&ixlib=rb-4.0.3&q=80&w=200",
    small_s3: "https://s3.us-west-2.amazonaws.com/images.unsplash.com/small/photo-1543726969-a1da85a6d334"
  }
};

export const OLD_PROMPT_TYPE = "old_prompt";
export const CHILD_PROMPT_TYPE = "child_prompt";
export const AI_PROMPT_TYPE = "ai_prompt";

export const COMPANIES_TABLE = "companies";
export const DEPARTMENTS_TABLE = "departments";
export const SKILLS_TABLE = "skills";
export const EMPTY_EDITOR_TEXT = "{\"blocks\":[{\"key\":\"fcn4v\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}";

export const aspectRatioMap: { [key: string]: string } = {
  "720x1280": "tall-portrait",
  "1080x1920": "tall-portrait",
  "1024x1280": "portrait",
  "1512x1920": "portrait",
  "1920x1920": "square",
  "1280x1280": "square",
  "1280x1024": "landscape",
  "1920x1512": "landscape",
  "1280x720": "wide-landscape",
  "1920x1080": "wide-landscape"
};

// ten minutes: https://developers.tiktok.com/doc/content-posting-api-media-transfer-guide
export const MAX_TIKTOK_VIDEO_DURATION = 600;
